<template>
  <div class="user">
    <keep-alive include="personal,personDetail">
      <router-view class="router-view"></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
</style>
